"use clinet";

import { FormSubmit } from "@/components/bulkimp-form/form-submit";
import { FormTextarea } from "@/components/bulkimp-form/form-textarea";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { useQueryClient } from "@tanstack/react-query";
import { AlignLeft } from "lucide-react";
import { useParams } from "next/navigation";
import { useState, useRef, ElementRef } from "react";
import { useEventListener, useOnClickOutside } from "usehooks-ts";
import { useAction } from "@/hooks/use-actions";
import { UpdateCardInfo } from "@/actions/main-board-actions/update-card-info";
import { toast } from "sonner";
import { CardWithSubTodos } from "@/types/pm-ai-types/pmAi-types";
import { Doc } from "@/convex/_generated/dataModel";
interface DescriptionProps {
  data: Doc<"todos">;
}
export const Description = ({
  data
}: DescriptionProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const queryClient = useQueryClient();
  const params = useParams();
  const textareaRef = useRef<ElementRef<"textarea">>(null);
  const formRef = useRef<ElementRef<"form">>(null);
  const {
    execute,
    fieldErrors
  } = useAction(UpdateCardInfo, {
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: ["card", data._id]
      });
      queryClient.invalidateQueries({
        queryKey: ["card-logs", data._id]
      });
      toast.success(`Card ${data.title} update`);
      disableEditing();
    },
    onError: error => {
      toast.error(error);
    }
  });
  const enableEditing = () => {
    setIsEditing(true);
    setTimeout(() => {
      textareaRef.current?.focus();
    });
  };
  const disableEditing = () => {
    setIsEditing(false);
  };
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      disableEditing();
    }
  };
  useEventListener("keydown", onKeyDown);
  useOnClickOutside(formRef, disableEditing);
  const onSubmit = (formData: FormData) => {
    const description = formData.get("description") as string;
    const boardId = params.boardId as string;
    console.log("[onSubmit] description: ", description);
    console.log("[onSubmit] boardId: ", boardId);
    console.log("[onSubmit] data._id: ", data._id);
    console.log("[onSubmit] data: ", data);
    execute({
      boardId,
      description,
      id: data._id
    });
  };
  return <div className="flex w-full items-start gap-x-3" data-sentry-component="Description" data-sentry-source-file="description.tsx">
      <AlignLeft className="mt-0.5 h-5 w-5 text-neutral-700 dark:text-neutral-300" data-sentry-element="AlignLeft" data-sentry-source-file="description.tsx" />
      <div className="w-full">
        <p className="mb-2 font-semibold text-neutral-700 dark:text-neutral-300">
          Description
        </p>
        {isEditing ? <form ref={formRef} className="space-y-2" action={onSubmit}>
            <FormTextarea id="description" ref={textareaRef} className="mt-2 w-full" placeholder="Add a more detailed" defaultValue={data.description || undefined} errors={fieldErrors} />
            <div className="flex items-center gap-x-2">
              <FormSubmit variant="solid" size="sm" radius="lg" className="text-md">
                Save
              </FormSubmit>
              <Button type="button" onClick={disableEditing} size={"sm"} variant={"ghost"}>
                Cancel
              </Button>
            </div>
          </form> : <div onClick={enableEditing} role="button" className="min-h-[78px] rounded-md bg-neutral-100 px-3.5 py-3 text-sm font-medium dark:bg-neutral-700">
            {data.description || "Add a more detailed description..."}
          </div>}
      </div>
    </div>;
};
Description.Skeleton = function DescriptionSkeleton() {
  return <div className="flex w-full items-start gap-x-3">
      {/* debugging: change all skeleton colors for dark mode*/}
      <Skeleton className="h-6 w-6 bg-neutral-200 dark:bg-neutral-700 " />
      <div className="w-full">
        <Skeleton className="mb-2 h-6 w-24 bg-neutral-200 dark:bg-neutral-700 " />
        <Skeleton className="h-[78px] w-full bg-neutral-200 dark:bg-neutral-700 " />
      </div>
    </div>;
};