"use client";

import { useRouter } from "next/navigation";
import React, { useEffect, useState } from "react";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../ui/command";
import TagComponent from "./tag";
import { PlusCircleIcon, TrashIcon, X } from "lucide-react";
import { Doc, Id } from "@/convex/_generated/dataModel";
import { useAuth } from "@clerk/nextjs";
import { api } from "@/convex/_generated/api";
import { useMutation } from "convex/react";
import { toast } from "sonner";
import { useAlertModal } from "@/hooks/use-alert-modal";
type Props = {
  parentId: string;
  getSelectedTags: (tags: Doc<"labels">[]) => void;
  defaultTags: Doc<"labels">[];
  defaultSelectedTags?: Doc<"labels">[];
};
const TagColors = ["BLUE", "ORANGE", "ROSE", "PURPLE", "GREEN"] as const;
export type TagColor = (typeof TagColors)[number];
const TagCreator = ({
  parentId,
  getSelectedTags,
  defaultTags,
  defaultSelectedTags
}: Props) => {
  const {
    orgId
  } = useAuth();
  const [selectedTags, setSelectedTags] = useState<Doc<"labels">[]>(defaultSelectedTags ?? []);
  const [tags, setTags] = useState<Doc<"labels">[]>([]);
  const router = useRouter();
  const [value, setValue] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const alertModal = useAlertModal();
  const createATag = useMutation(api.pmAi.labels.createALabel) ?? undefined;
  const deleteTag = useMutation(api.pmAi.labels.deleteATag) ?? undefined;
  useEffect(() => {
    getSelectedTags(selectedTags);
  }, [selectedTags]);
  useEffect(() => {
    if (defaultSelectedTags) {
      setSelectedTags(defaultSelectedTags);
    }
  }, []);
  useEffect(() => {
    if (defaultTags) {
      if (defaultTags) setTags(defaultTags);
    }
  }, [defaultTags]);
  const handleDeleteSelection = async (tagId: string) => {
    setSelectedTags(selectedTags.filter(tag => tag._id !== tagId));
  };
  const handleAddTag = async () => {
    if (!value) {
      toast.error("Tags need to have a name");
      return;
    }
    if (!selectedColor) {
      toast.error("Please select a color");
      return;
    }
    try {
      if (!selectedColor || selectedColor === undefined) return;
      const response = await createATag({
        color: selectedColor,
        name: value,
        parentId: parentId,
        type: "user"
      });
      if (response?.error) {
        toast.error(response.error.message);
        return;
      }
      if (!response?.data) {
        toast.error("Could not create tag");
        return;
      }
      toast.success("Tag created!");
      setTags([...tags, response.data]);
      setValue("");
      setSelectedColor("");
    } catch (error) {
      toast.error("Could not create tag");
    }
  };
  const handleAddSelection = (tag: Doc<"labels">) => {
    if (selectedTags.every(t => t._id !== tag._id)) {
      setSelectedTags([...selectedTags, tag]);
    }
  };
  const handleDeleteTag = async (tagId: Id<"labels">) => {
    if (tagId === process.env.NEXT_PUBLIC_AI_LABEL_ID || tagId === process.env.NEXT_PUBLIC_NA_LABEL_ID) {
      toast.error("Cannot delete system tags");
      return;
    }
    alertModal.onOpen("tag", async () => {
      try {
        setTags(tags.filter(tag => tag._id !== tagId));
        await deleteTag({
          _id: tagId
        });
        toast.success("Tag deleted!");
        router.refresh();
        alertModal.onClose();
      } catch (error) {
        toast.error("Could not delete tag");
        alertModal.setIsLoading(false);
      }
    });
  };
  return <Command className="bg-transparent" data-sentry-element="Command" data-sentry-component="TagCreator" data-sentry-source-file="tag-creator.tsx">
      {!!selectedTags.length && <div className="flex flex-wrap gap-2 rounded-md border-1 border-border bg-background p-1">
          {selectedTags.map(tag => <div className="flex items-center" key={tag._id}>
              <TagComponent title={tag.name} colorName={tag.color ?? ""} />
              <X size={16} className="cursor-pointer text-muted-foreground" onClick={() => handleDeleteSelection(tag._id)} />
            </div>)}
        </div>}
      <div className="my-2 flex items-center gap-2">
        {TagColors.map(colorName => <TagComponent key={colorName} selectedColor={setSelectedColor} title="" colorName={colorName} isSelected={colorName === selectedColor} />)}
      </div>
      <div className="relative">
        <CommandInput placeholder="Search for tag..." value={value} onValueChange={setValue} className="my-0 py-0" data-sentry-element="CommandInput" data-sentry-source-file="tag-creator.tsx" />
        <PlusCircleIcon onClick={handleAddTag} size={20} className="absolute right-4 top-1/2 -translate-y-1/2 transform cursor-pointer text-muted-foreground transition-all hover:text-primary" data-sentry-element="PlusCircleIcon" data-sentry-source-file="tag-creator.tsx" />
      </div>
      <CommandList className="sidebar mt-1 !max-h-[200px]" data-sentry-element="CommandList" data-sentry-source-file="tag-creator.tsx">
        <CommandGroup heading="Tags" data-sentry-element="CommandGroup" data-sentry-source-file="tag-creator.tsx">
          {tags.map(tag => <CommandItem key={tag._id} className="flex items-center justify-between !bg-transparent !font-light hover:!bg-secondary">
              <div onClick={() => handleAddSelection(tag)} className="cursor-pointer">
                <TagComponent title={tag.name} colorName={tag.color ?? ""} />
              </div>

              {tag._id !== process.env.NEXT_PUBLIC_AI_LABEL_ID! && tag._id !== process.env.NEXT_PUBLIC_NA_LABEL_ID! && <TrashIcon size={16} className="cursor-pointer text-muted-foreground transition-all hover:text-rose-400" onClick={() => handleDeleteTag(tag._id)} />}
            </CommandItem>)}
        </CommandGroup>
        <CommandEmpty data-sentry-element="CommandEmpty" data-sentry-source-file="tag-creator.tsx">No results found.</CommandEmpty>
      </CommandList>
    </Command>;
};
export default TagCreator;