import { Doc } from "@/convex/_generated/dataModel";
import { DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../../ui/dialog";
import { Button } from "../../ui/button";
import { Label } from "../../ui/label";
import { Calendar, ChevronDown, Flag, Hash, Tag, Trash2 } from "lucide-react";
import { format } from "date-fns";
import { useMutation, useQuery } from "convex/react";
import { api } from "@/convex/_generated/api";
import { useEffect, useState } from "react";
import Task from "../todos/task";
import { AddTaskWrapper } from "./add-task-button";
import SuggestMissingTasks from "./suggest-tasks";
import { Header } from "@/components/modals/card-modal/header";
import { Description } from "@/components/modals/card-modal/description";
import { Activity } from "@/components/modals/card-modal/activity";
import { ExtraInfo } from "@/components/modals/card-modal/card-extra-info";
import { Actions } from "@/components/modals/card-modal/actions";
import { CardWithAllRelationalInfo } from "@/types/funnelbuilder-types/funnelbuilder-types";
import { toast } from "sonner";
interface AddTaskDialogProps {
  data: CardWithAllRelationalInfo;
  hideProjects?: boolean;
}
export default function AddTaskDialog({
  data,
  hideProjects
}: AddTaskDialogProps) {
  const {
    title,
    description,
    projectId,
    labelIds,
    priority,
    dueDate,
    _id
  } = data;
  const listsResponse = useQuery(api.pmAi.lists.getListsByProjectId, {
    boardId: projectId
  });
  const listTitle = listsResponse?.data?.find(list => list._id === data.listId)?.title;
  const cardData = {
    ...data,
    listTitle: listTitle
  };
  const labelsResponse = useQuery(api.pmAi.labels.getLabelByLabelId, {
    labelIds
  });
  const label = labelsResponse?.data?.[0] || null;
  const incompleteSubtodosResponse = useQuery(api.pmAi.subTodos.inCompleteSubTodos, {
    parentId: _id
  });
  const incompleteSubtodosByProject = incompleteSubtodosResponse?.data ?? [];
  const completedSubtodosResponse = useQuery(api.pmAi.subTodos.completedSubTodos, {
    parentId: _id
  });

  // TODO PERFORMANCE: remove useeffect and use state to conditionally render the error
  useEffect(() => {
    if (completedSubtodosResponse?.error) {
      toast.error(completedSubtodosResponse.error.message);
    }
    if (incompleteSubtodosResponse?.error) {
      toast.error(incompleteSubtodosResponse.error.message);
    }
    if (labelsResponse?.error) {
      toast.error(labelsResponse.error.message);
    }
    if (listsResponse?.error) {
      toast.error(listsResponse.error.message);
    }
  }, [completedSubtodosResponse?.error, incompleteSubtodosResponse?.error, labelsResponse?.error, listsResponse?.error]);
  const completedSubtodosByProject = completedSubtodosResponse?.data ?? [];
  const checkASubTodoMutation = useMutation(api.pmAi.subTodos.checkASubTodo);
  const unCheckASubTodoMutation = useMutation(api.pmAi.subTodos.unCheckASubTodo);
  const deleteATodoMutation = useMutation(api.pmAi.todos.deleteATodo);
  const auditLogsData = undefined;
  const [todoDetails, setTodoDetails] = useState<Array<{
    labelName: string;
    value: string;
    icon: React.ReactNode;
  }>>([]);
  useEffect(() => {
    const formattedData = [{
      labelName: "Project",
      value: listTitle || "",
      icon: <Hash className="h-4 w-4 capitalize text-primary" />
    }, {
      labelName: "Due date",
      value: format(dueDate || new Date(), "MMM dd yyyy"),
      icon: <Calendar className="h-4 w-4 capitalize text-primary" />
    }, {
      labelName: "Priority",
      value: priority || "",
      icon: <Flag className="h-4 w-4 capitalize text-primary" />
    }, {
      labelName: "Label",
      value: label?.name || "",
      icon: <Tag className="h-4 w-4 capitalize text-primary" />
    }];
    if (data) {
      setTodoDetails(formattedData);
    }
  }, [dueDate, label?.name, priority, listTitle]);
  const handleDeleteTodo = (e: any) => {
    e.preventDefault();
    const deletedId = deleteATodoMutation({
      taskId: _id
    });
    if (deletedId !== undefined) {
      toast.success("🗑️ Successfully deleted");
    }
  };
  return <DialogContent className="sidebar mb-4 flex max-w-4xl flex-col overflow-y-auto text-right md:flex-row lg:h-4/6 lg:justify-between" data-sentry-element="DialogContent" data-sentry-component="AddTaskDialog" data-sentry-source-file="add-task-dialog.tsx">
      <DialogHeader className="w-full" data-sentry-element="DialogHeader" data-sentry-source-file="add-task-dialog.tsx">
        <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="add-task-dialog.tsx">
          {!data ? <Header.Skeleton /> : <Header data={cardData} />}
        </DialogTitle>
        <div className="grid grid-cols-1 md:grid-cols-4 md:gap-4">
          <div className="col-span-3">
            <div className="w-full space-y-6">
              {!cardData ? <Description.Skeleton /> : <Description data={cardData} />}

              {/* SubTasks and Add new subtasks */}
              <div className="mt-12 flex flex-wrap items-center gap-1 border-b-2 border-gray-100 pb-2 sm:justify-between lg:gap-0 ">
                <div className="flex gap-1">
                  <ChevronDown className="h-5 w-5 text-primary" data-sentry-element="ChevronDown" data-sentry-source-file="add-task-dialog.tsx" />
                  <div className="flex text-sm font-bold text-gray-900 dark:text-gray-100">
                    Sub-tasks
                  </div>
                </div>
                <div>
                  <SuggestMissingTasks projectId={projectId} title={title} description={description} parentId={_id} isSubTask={true} data-sentry-element="SuggestMissingTasks" data-sentry-source-file="add-task-dialog.tsx" />
                </div>
              </div>
              <div className="pl-4">
                {incompleteSubtodosByProject.map(task => {
                return <Task key={task._id} data={task} isCompleted={task.isCompleted} handleOnChange={() => checkASubTodoMutation({
                  taskId: task._id
                })} />;
              })}
                <div className="pb-4">
                  <AddTaskWrapper parentTask={data} hideProjects={hideProjects} data-sentry-element="AddTaskWrapper" data-sentry-source-file="add-task-dialog.tsx" />
                </div>
                {completedSubtodosByProject.map(task => {
                return <Task key={task._id} data={task} isCompleted={task.isCompleted} handleOnChange={() => unCheckASubTodoMutation({
                  taskId: task._id
                })} />;
              })}
              </div>

              <DialogFooter className="pb-10" data-sentry-element="DialogFooter" data-sentry-source-file="add-task-dialog.tsx">
                {/* ACTIVITY LOG */}
                {!auditLogsData ? <Activity.Skeleton /> : <Activity items={auditLogsData} />}
              </DialogFooter>
            </div>
          </div>
          <div className="flex flex-col">
            {!cardData ? <ExtraInfo.Skeleton /> : <ExtraInfo data={cardData} />}
            {!cardData ? <Actions.Skeleton /> : <Actions data={cardData} />}
          </div>
        </div>
      </DialogHeader>
      {/* <div className="flex flex-col gap-2 bg-gray-100 lg:w-1/2">
        {todoDetails.map(({ labelName, value, icon }, idx) => (
          <div
            key={`${value}-${idx}`}
            className="grid gap-2 p-4 border-b-2 w-full"
          >
            <Label className="flex items-start">{labelName}</Label>
            <div className="flex text-left items-center justify-start gap-2 pb-2">
              {icon}
              <div className="text-sm">{value}</div>
            </div>
          </div>
        ))}
        <div className="flex gap-2 p-4 w-full justify-end">
          <form onSubmit={(e) => handleDeleteTodo(e)}>
            <button type="submit">
              <Trash2 className="w-5 h-5" />
            </button>
          </form>
        </div>
       </div> */}
    </DialogContent>;
}