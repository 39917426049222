import { Doc } from "@/convex/_generated/dataModel";
import clsx from "clsx";
import AddTaskDialog from "../add-tasks/add-task-dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { Calendar, GitBranch, Tag } from "lucide-react";
import moment from "moment";
import { CardWithAllRelationalInfo } from "@/types/funnelbuilder-types/funnelbuilder-types";
export function isSubTodo(data: Doc<"todos"> | Doc<"subTodos">): data is Doc<"subTodos"> {
  return "parentId" in data;
}
export default function Task({
  data,
  isCompleted,
  handleOnChange,
  showDetails = false
}: {
  data: Doc<"todos"> | Doc<"subTodos"> | CardWithAllRelationalInfo;
  isCompleted: boolean;
  handleOnChange: any;
  showDetails?: boolean;
}) {
  const {
    title,
    dueDate
  } = data;
  return <div key={data._id} className="flex items-center space-x-2 border-b-2 border-gray-100 p-2 animate-in fade-in dark:border-gray-800" data-sentry-component="Task" data-sentry-source-file="task.tsx">
      <Dialog data-sentry-element="Dialog" data-sentry-source-file="task.tsx">
        <div className="flex w-full items-center justify-end gap-2">
          <div className="flex w-full gap-2">
            <Checkbox id="todo" className={clsx("h-5 w-5 rounded-xl", isCompleted && "border-gray-300 data-[state=checked]:bg-gray-300")} checked={isCompleted} onCheckedChange={handleOnChange} data-sentry-element="Checkbox" data-sentry-source-file="task.tsx" />
            <DialogTrigger asChild data-sentry-element="DialogTrigger" data-sentry-source-file="task.tsx">
              <div className="flex flex-col items-start">
                <button className={clsx("text-left text-sm font-normal", isCompleted && "text-foreground/30 line-through")}>
                  {title}
                </button>
                {showDetails && <div className="flex gap-2">
                    <div className="flex items-center justify-center gap-1">
                      <GitBranch className="h-3 w-3 text-foreground/70" />
                      <p className="text-xs text-foreground/70"></p>
                    </div>
                    <div className="flex items-center justify-center gap-1">
                      <Calendar className="h-3 w-3 text-primary" />
                      <p className="text-xs text-primary">
                        {moment(dueDate).format("LL")}
                      </p>
                    </div>
                  </div>}
              </div>
            </DialogTrigger>
          </div>
          {!isSubTodo(data) && <AddTaskDialog data={data as CardWithAllRelationalInfo} />}
        </div>
      </Dialog>
    </div>;
}