"use client";

import React, { useState } from "react";
import clsx from "clsx";
interface TagComponentProps {
  title: string;
  colorName: string;
  selectedColor?: (color: string) => void;
  isSelected?: boolean;
}
const TagComponent: React.FC<TagComponentProps> = ({
  colorName,
  title,
  selectedColor,
  isSelected
}) => {
  const [selectedColorName, setSelectedColorName] = useState<string | null>(null);
  return <div className={clsx("p-[6px] rounded-sm flex-shrink-0 text-xs cursor-pointer", {
    "opacity-100": title || isSelected,
    // Always 100% opacity if there's a title or if it's the selected color
    "opacity-70": !title && !isSelected,
    // 50% opacity if no title and not the selected color
    "bg-[#57acea]/10 text-[#57acea]": colorName === "BLUE",
    "bg-[#ffac7e]/10 text-[#ffac7e]": colorName === "ORANGE",
    "bg-rose-500/10 text-rose-500": colorName === "ROSE",
    "bg-emerald-400/10 text-emerald-400": colorName === "GREEN",
    "bg-purple-400/10 text-purple-400": colorName === "PURPLE",
    "border-[1px] border-[#57acea]": colorName === "BLUE" && !title,
    "border-[1px] border-[#ffac7e]": colorName === "ORANGE" && !title,
    "border-[1px] border-rose-500": colorName === "ROSE" && !title,
    "border-[1px] border-emerald-400": colorName === "GREEN" && !title,
    "border-[1px] border-purple-400": colorName === "PURPLE" && !title
  })} key={colorName} onClick={() => {
    if (selectedColor) selectedColor(colorName);
  }} data-sentry-component="TagComponent" data-sentry-source-file="tag.tsx">
      {title}
    </div>;
};
export default TagComponent;