"use client";

import { api } from "@/convex/_generated/api";
import { useAction } from "convex/react";
import React, { useEffect, useState } from "react";
import { Id } from "@/convex/_generated/dataModel";
import { Loader, Sparkles } from "lucide-react";
import { Tooltip, Button } from "@nextui-org/react";
import { usePathname } from "next/navigation";
import { enableEmbedTasks } from "@/lib/constants/constants";
import { toast } from "sonner";
export default function SuggestMissingTasks({
  projectId,
  isSubTask = false,
  title = "",
  description = "",
  parentId
}: {
  projectId: Id<"projectBoards">;
  isSubTask?: boolean;
  title?: string;
  description?: string;
  parentId?: Id<"todos">;
}) {
  const pathname = usePathname();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoadingSuggestMissingTasks, setIsLoadingSuggestMissingTasks] = useState(false);
  const embedTasks = enableEmbedTasks;
  const suggestMissingTasks = useAction(api.pmAi.openai.suggestMissingItemsWithAi) || [];
  const suggestMissingSubTasks = useAction(api.pmAi.openai.suggestMissingSubItemsWithAi) || [];
  const handleMissingTasks = async () => {
    setIsLoadingSuggestMissingTasks(true);
    try {
      const result = await suggestMissingTasks({
        projectId,
        embedTasks
      });
      if (result?.error) {
        toast.error(result.error.message);
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.log("Error in suggestMissingTasks", error);
    } finally {
      setIsLoadingSuggestMissingTasks(false);
    }
  };
  const handleMissingSubTasks = async () => {
    setIsLoadingSuggestMissingTasks(true);
    try {
      if (parentId) {
        const result = await suggestMissingSubTasks({
          projectId,
          title,
          description,
          parentId,
          embedTasks
        });
        if (result?.error) {
          toast.error(result.error.message);
          throw new Error(result.error.message);
        }
      }
    } catch (error) {
      console.log("Error in suggestMissingSubTasks", error);
    } finally {
      setIsLoadingSuggestMissingTasks(false);
    }
  };
  useEffect(() => {
    setIsDisabled(pathname.includes("/settings"));
  }, [pathname]);
  return <Tooltip content="AI analyzes your current tasks and suggests what might be missing" delay={500} data-sentry-element="Tooltip" data-sentry-component="SuggestMissingTasks" data-sentry-source-file="suggest-tasks.tsx">
      <Button variant={"solid"} isDisabled={isLoadingSuggestMissingTasks || isDisabled} isLoading={isLoadingSuggestMissingTasks} onPress={isSubTask ? handleMissingSubTasks : handleMissingTasks} className="gap-2" data-sentry-element="Button" data-sentry-source-file="suggest-tasks.tsx">
        <>
          {isLoadingSuggestMissingTasks ? "Thinking..." : "AI Assist"}
          <Sparkles className="h-4 w-4" data-sentry-element="Sparkles" data-sentry-source-file="suggest-tasks.tsx" />
        </>
      </Button>
    </Tooltip>;
}