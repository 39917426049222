import { create } from "zustand";
import { CardWithAllRelationalInfo } from "@/types/funnelbuilder-types/funnelbuilder-types";

interface CardModalStore {
  data?: CardWithAllRelationalInfo;
  isOpen: boolean;
  onOpen: (data: CardWithAllRelationalInfo) => void;
  onClose: () => void;
}

export const useCardModal = create<CardModalStore>((set, get) => ({
  data: undefined,
  isOpen: false,
  onOpen: (data: CardWithAllRelationalInfo) => {
    console.log("Opening modal with data:", data);
    set({ isOpen: true, data });
  },
  onClose: () => {
    console.log("Closing modal, current state:", get().isOpen);
    set({ isOpen: false, data: undefined });
    console.log("New state after close:", get().isOpen);
  },
}));
