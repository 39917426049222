"use client";

import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { Copy, Trash } from "lucide-react";
import { useAction } from "@/hooks/use-actions";
import { copyCard } from "@/actions/main-board-actions/copy-card";
import { deleteCard } from "@/actions/main-board-actions/delete-card";
import { useParams } from "next/navigation";
import { toast } from "sonner";
import { useCardModal } from "@/hooks/use-card-model";
import { CardWithSubTodos } from "@/types/pm-ai-types/pmAi-types";
import { Doc } from "@/convex/_generated/dataModel";
interface ActionsProps {
  data: Doc<"todos">;
}
export const Actions = ({
  data
}: ActionsProps) => {
  const params = useParams();
  const cardModal = useCardModal();
  const {
    execute: executeCopyCard,
    isLoading: isLoadingCopy
  } = useAction(copyCard, {
    onSuccess: data => {
      toast.success(`Card "${data.title}" copied`);
      cardModal.onClose();
    },
    onError: error => {
      toast.error(error);
    }
  });
  const {
    execute: executeDeleteCard,
    isLoading: isLoadingDelete
  } = useAction(deleteCard, {
    onSuccess: data => {
      toast.success(`Card "${data.title}" deleted`);
      cardModal.onClose();
    },
    onError: error => {
      toast.error(error);
    }
  });
  const onCopy = () => {
    const boardId = params.boardId as string;
    console.log("[actions] boardId: ", boardId);
    console.log("[actions] data: ", data);

    // cardModal.onClose();

    executeCopyCard({
      id: data._id,
      boardId
    });
  };
  const onDelete = () => {
    const boardId = params.boardId as string;
    executeDeleteCard({
      id: data._id,
      boardId
    });
  };
  return <div className="mt-2 space-y-2" data-sentry-component="Actions" data-sentry-source-file="actions.tsx">
      <p className="text-sm font-semibold">Actions</p>
      <Button onClick={onCopy} disabled={isLoadingCopy} variant={"grey"} className="w-full justify-start" size={"inline"} data-sentry-element="Button" data-sentry-source-file="actions.tsx">
        <Copy className="mr-2 h-4 w-4" data-sentry-element="Copy" data-sentry-source-file="actions.tsx" />
        Copy
      </Button>
      <Button onClick={onDelete} disabled={isLoadingDelete} variant={"grey"} className="w-full justify-start" size={"inline"} data-sentry-element="Button" data-sentry-source-file="actions.tsx">
        <Trash className="mr-2 h-4 w-4" data-sentry-element="Trash" data-sentry-source-file="actions.tsx" />
        Delete
      </Button>
    </div>;
};
Actions.Skeleton = function ActionsSkeleton() {
  return <div className="mt-2 space-y-2">
      <Skeleton className="h-4 w-20 bg-neutral-200 dark:bg-neutral-700 " />
      <Skeleton className="h-8 w-full bg-neutral-200 dark:bg-neutral-700 " />
      <Skeleton className="h-8 w-full bg-neutral-200 dark:bg-neutral-700 " />
    </div>;
};