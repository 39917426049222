"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { CalendarIcon, Text } from "lucide-react";
import { Textarea } from "../../ui/textarea";
import { CardFooter } from "../../ui/card";
import { Dispatch, SetStateAction } from "react";
import { Calendar } from "../../ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
import { format } from "date-fns";
import moment from "moment";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select";
import { Doc, Id } from "@/convex/_generated/dataModel";
import { useAction, useMutation, useQuery } from "convex/react";
import { api } from "@/convex/_generated/api";
import { GET_STARTED_PROJECT_ID } from "@/utils/pm-ai-utils/pm-ai-utils";
import { cn } from "@/lib/utils";
import { LoadingButton } from "@/components/global-ui/loading-button";
import { useEffect, useState } from "react";
import TagCreator from "@/components/funnelbuilder/tag-creator";
import { useAuth } from "@clerk/nextjs";
import { useRouter } from "next/navigation";
import { toast } from "sonner";
import { enableEmbedTasks } from "@/lib/constants/constants";
import { Button } from "@nextui-org/react";
const FormSchema = z.object({
  title: z.string().min(2, {
    message: "Task name must be at least 2 characters."
  }),
  description: z.string().optional().default(""),
  dueDate: z.date({
    required_error: "A due date is required"
  }),
  // priority: z.string().min(1, { message: "Please Select Priority" }),
  priority: z.enum(["Low", "Normal", "High", "Urgent"]),
  projectId: z.string().min(1, {
    message: "Please select a Project"
  })
  // labelIds: z.array(labelOptionSchema).min(1),
  // labelIds: z.array(z.string()).min(1),
});
interface TodoActionResult<T> {
  error?: {
    code: string;
    message: string;
  };
  data?: T;
}
export default function AddTaskInline({
  setShowAddTask,
  parentTask,
  projectId: myProjectId,
  hideProjects
}: {
  setShowAddTask: Dispatch<SetStateAction<boolean>>;
  parentTask?: Doc<"todos">;
  projectId?: Id<"projectBoards">;
  hideProjects?: boolean;
}) {
  // console.log("[AddTaskInline] myProjectId: ", myProjectId);
  // console.log("[AddTaskInline] parentTask: ", parentTask);

  const router = useRouter();
  const {
    orgId
  } = useAuth();
  const projectId = myProjectId || parentTask?.projectId;
  const [tags, setTags] = useState<Doc<"labels">[]>([]);
  const [selectedProject, setSelectedProject] = useState<string>("");
  const labelIds = parentTask?.labelIds || [process.env.NEXT_PUBLIC_AI_LABEL_ID as Id<"labels">];
  const priority = parentTask?.priority || "Normal";
  const parentId = parentTask?._id;
  const projectsResponse = useQuery(api.pmAi.pmAiProjects.getProjects);
  // const projects = useQuery(api.pmAi.pmAiProjects.getProjects) ?? [];
  const projects = projectsResponse?.data ?? [];
  const createASubTodoEmbeddings = useAction(api.pmAi.subTodos.createSubTodoAndEmbeddings);
  const createTodoEmbeddings = useAction(api.pmAi.todos.createTodoAndEmbeddings);
  const labelsResponse = useQuery(api.pmAi.labels.getLabels);
  const [availableLabels, setAvailableLabels] = useState<Doc<"labels">[]>([]);
  useEffect(() => {
    if (labelsResponse?.error) {
      toast.error(labelsResponse.error.message);
      return;
    }
    if (labelsResponse?.data) {
      setAvailableLabels(labelsResponse.data);
    }
  }, [labelsResponse]);
  const defaultLabelIds = parentTask?.labelIds;
  const defaultValues = {
    title: "",
    description: "",
    priority,
    dueDate: new Date(),
    projectId
    // labelIds: defaultLabelIds,
  };
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues
  });
  const embedTasks = enableEmbedTasks;
  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      const {
        title,
        description,
        priority,
        dueDate,
        projectId
      } = data;
      const labelIdsMapped = tags.map(tag => tag._id);
      if (projectId) {
        if (parentId) {
          //subtodo
          const result = await createASubTodoEmbeddings({
            parentId,
            title,
            description,
            priority,
            dueDate: moment(dueDate).valueOf(),
            projectId: projectId as Id<"projectBoards">,
            labelIds: labelIdsMapped as Id<"labels">[],
            embedTasks
          });
          if (result?.error) {
            console.log("[AddTaskInline] Error creating subtask:", result.error);
            toast.error(result.error?.message || "Failed to create subtask");
            return;
          }
          toast.success("Task created successfully", {
            duration: 3000
          });
          form.reset({
            ...defaultValues
          });
          setShowAddTask(false); // Close the dialog
        } else {
          const result = await createTodoEmbeddings({
            title,
            description,
            priority,
            dueDate: moment(dueDate).valueOf(),
            projectId: projectId as Id<"projectBoards">,
            labelIds: labelIdsMapped as Id<"labels">[],
            embedTasks
          });
          if ("error" in result) {
            console.log("[AddTaskInline] Error creating task:", result.error);
            toast.error(result?.error?.message || "Failed to create task");
            return;
          }
          toast.success("Task created successfully", {
            duration: 3000
          });
          form.reset({
            ...defaultValues
          });
          setShowAddTask(false); // Close the dialog
        }
      }
    } catch (error) {
      console.log("[AddTaskInline] Unexpected error:", error);
      toast.error("An unexpected error occurred while creating the task");
    }
  }

  // Update the form when projects are loaded
  useEffect(() => {
    if (projectsResponse?.data && projectsResponse.data.length > 0 && !projectId && !selectedProject) {
      const firstProjectId = projectsResponse.data[0]._id;
      setSelectedProject(firstProjectId);
      form.setValue("projectId", firstProjectId);
    }
  }, [projectsResponse?.data, form, projectId, selectedProject]);
  const isLoading = form.formState.isLoading || form.formState.isSubmitting;
  if (!orgId) {
    router.push("/select-org");
  }
  return <div data-sentry-component="AddTaskInline" data-sentry-source-file="add-task-inline.tsx">
      <Form {...form} data-sentry-element="Form" data-sentry-source-file="add-task-inline.tsx">
        <form onSubmit={form.handleSubmit(onSubmit)} className="mt-2 space-y-2 rounded-md border-1 border-foreground/20 border-gray-200 p-2 px-3">
          <FormField control={form.control} name="title" render={({
          field
        }) => <FormItem className="ml-6 ">
                <FormControl>
                  <Input id="title" type="text" placeholder="Title" required className="border  " {...field} />
                </FormControl>
              </FormItem>} data-sentry-element="FormField" data-sentry-source-file="add-task-inline.tsx" />
          <FormField control={form.control} name="description" render={({
          field
        }) => <FormItem>
                <FormControl>
                  <div className="flex items-start gap-2">
                    <Text className="ml-auto h-4 w-4 opacity-50" />
                    <Textarea id="description" placeholder="Description" className="resize-none" {...field} />
                  </div>
                </FormControl>
              </FormItem>} data-sentry-element="FormField" data-sentry-source-file="add-task-inline.tsx" />
          <div className="flex gap-2">
            <FormField control={form.control} name="dueDate" render={({
            field
          }) => <FormItem className="flex flex-col">
                  <FormLabel>Due Date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button variant={"flat"} className={cn("flex w-[240px] gap-2 pl-3 text-left font-normal", !field.value && "text-muted-foreground")}>
                          {field.value ? format(field.value, "PPP") : <span>Pick a date</span>}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar mode="single" selected={field.value} onSelect={field.onChange} initialFocus />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>} data-sentry-element="FormField" data-sentry-source-file="add-task-inline.tsx" />
            <FormField control={form.control} name="priority" render={({
            field
          }) => <FormItem className="flex flex-col">
                  <FormLabel>Priority</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={priority}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select Priority" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {["Low", "Normal", "High", "Urgent"].map((item, idx) => <SelectItem key={idx} value={item}>
                          {/* Priority: {item} */}
                          {item}
                        </SelectItem>)}
                    </SelectContent>
                  </Select>

                  <FormMessage />
                </FormItem>} data-sentry-element="FormField" data-sentry-source-file="add-task-inline.tsx" />
          </div>

          <div className="rounded-md border p-4">
            <h3>Add tags</h3>
            <TagCreator parentId={orgId!} getSelectedTags={setTags} defaultTags={availableLabels} data-sentry-element="TagCreator" data-sentry-source-file="add-task-inline.tsx" />
          </div>

          {/* THIS IS NEEDED - Just disabling until multi projects are up but do not delete! */}
          {/* {!hideProjects && (
            <FormField
              control={form.control}
              name="projectId"
              disabled={projects.length === 0 || !projects}
              render={({ field }) => (
                <FormItem>
                  <Select
                    onValueChange={(value) => {
                      setSelectedProject(value);
                      field.onChange(value);
                    }}
                    value={selectedProject || projectId || field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a Project" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {projects.map(
                        (project: Doc<"projectBoards">, idx: number) => (
                          <SelectItem key={idx} value={project._id}>
                            {project?.title}
                          </SelectItem>
                        ),
                      )}
                      {(!projects || projects.length === 0) && (
                        <SelectItem key={"1"} value={"N/A"}>
                          {"Please create a board first..."}
                        </SelectItem>
                      )}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
           )} */}

          <CardFooter className="flex flex-col gap-2 border-t-2 pb-2 pt-3 lg:flex-row lg:justify-between" data-sentry-element="CardFooter" data-sentry-source-file="add-task-inline.tsx">
            <div className="w-full lg:w-1/4"></div>
            <div className="flex gap-3 self-end">
              <Button className="bg-gray-300/40 px-6 text-gray-950 hover:bg-gray-300" size={"sm"} variant={"flat"} onClick={() => setShowAddTask(false)} type="button" data-sentry-element="Button" data-sentry-source-file="add-task-inline.tsx">
                Cancel
              </Button>

              {/* <LoadingButton
                type="submit"
                size={"sm"}
                loadingText="Adding..."
                isDisabled={projects.length === 0 || !projects}
                isLoading={isLoading}
                className=" text-md bg-primary/90"
               >
                Add task
               </LoadingButton> */}
              <Button size={"sm"} variant={"solid"} type="submit" color="primary" isDisabled={projects.length === 0 || !projects} isLoading={isLoading} data-sentry-element="Button" data-sentry-source-file="add-task-inline.tsx">
                Add task
              </Button>
            </div>
          </CardFooter>
        </form>
      </Form>
    </div>;
}