// import { AuditLog } from "@prisma/client";

// import { generateLogMessage } from "@/lib/generate-log-message";
import { Avatar } from "@nextui-org/react";
import { format } from "date-fns";
interface ActivityItemProps {
  // data: AuditLog;
  data: any;
}
export const ActivityItem = ({
  data
}: ActivityItemProps) => {
  return <li className="flex items-center gap-x-2" data-sentry-component="ActivityItem" data-sentry-source-file="activity-item.tsx">
      <Avatar className="h-8 w-8" src={data.userImage} data-sentry-element="Avatar" data-sentry-source-file="activity-item.tsx" />

      <div className="flex flex-col space-y-0.5">
        <p className="text-sm text-muted-foreground">
          {/* Debugging font */}
          <span className="font-semibold lowercase text-neutral-700 dark:text-neutral-200">
            {data.userName}
          </span>{" "}
          {/* {generateLogMessage(data)} */}
        </p>
        <p className="text-sm text-muted-foreground">
          {format(new Date(data.createdAt), "MMM d, yyyy 'at' h:mm a")}
        </p>
      </div>
    </li>;
};