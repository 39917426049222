"use client";

import { toast } from "sonner";
import { ElementRef, useEffect, useRef, useState } from "react";
import { Layout } from "lucide-react";
import { useParams } from "next/navigation";
import { useQueryClient } from "@tanstack/react-query";
import { Skeleton } from "@/components/ui/skeleton";
import { FormInput } from "@/components/bulkimp-form/form-input";
import { useAction } from "@/hooks/use-actions";
import { UpdateCardInfo } from "@/actions/main-board-actions/update-card-info";
import { CardWithSubTodos } from "@/types/pm-ai-types/pmAi-types";
import { Doc } from "@/convex/_generated/dataModel";
interface HeaderProps {
  data: Doc<"todos"> & {
    listTitle?: string;
  };
}
export const Header = ({
  data
}: HeaderProps) => {
  const [title, setTitle] = useState(data.title);
  const [isEditing, setIsEditing] = useState(false);
  const queryClient = useQueryClient();
  const params = useParams();
  const inputRef = useRef<ElementRef<"input">>(null);
  const {
    execute
  } = useAction(UpdateCardInfo, {
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: ["card", data._id]
      });
      queryClient.invalidateQueries({
        queryKey: ["card-logs", data._id]
      });
      toast.success(`Renamed to "${data.title}"`);
      setTitle(data.title);
    },
    onError: error => {
      toast.error(error);
    }
  });
  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]); // Add this useEffect to auto-focus the input

  const onBlur = () => {
    inputRef.current?.form?.requestSubmit();
  };
  const onSubmit = (formData: FormData) => {
    const title = formData.get("title") as string;
    const boardId = params.boardId as string;
    if (title === data.title) {
      return;
    }
    execute({
      title,
      boardId,
      id: data._id
    });
  };
  return <div className="mb-6 flex w-full items-start gap-x-3" data-sentry-component="Header" data-sentry-source-file="header.tsx">
      <Layout className="mt-1 h-5 w-5 text-neutral-700 dark:text-neutral-200" data-sentry-element="Layout" data-sentry-source-file="header.tsx" />
      <div className="w-full">
        {isEditing ? <form action={onSubmit}>
            <FormInput ref={inputRef} onBlur={onBlur} id="title" defaultValue={title} className="relative -left-1.5 mb-0.5 w-[95%] truncate border-transparent bg-transparent px-1 text-xl font-semibold text-neutral-700 focus-visible:border-input focus-visible:bg-white dark:text-neutral-200 dark:focus-visible:bg-white/20" />
          </form> : <h1 className="cursor-pointer text-xl  font-semibold text-neutral-700 dark:text-neutral-200" onClick={() => setIsEditing(true)}>
            {title}
          </h1>}
        <p className="text-sm text-muted-foreground">
          in list <span className="underline">{data.listTitle}</span>
        </p>
      </div>
    </div>;
};
Header.Skeleton = function HeaderSkeleton() {
  return <div className="mb-6 flex items-start gap-x-3">
      <Skeleton className="mt-1 h-6 w-6 bg-neutral-200 dark:bg-neutral-700 " />
      <div>
        <Skeleton className="mb-1 h-6 w-24 bg-neutral-200 dark:bg-neutral-700 " />
        <Skeleton className="h-4 w-12 bg-neutral-200 dark:bg-neutral-700 " />
      </div>
    </div>;
};