"use client";

import { ActivityItem } from "@/components/activity-item";
import { Skeleton } from "@/components/ui/skeleton";
// import { AuditLog } from "@prisma/client";
import { ActivityIcon } from "lucide-react";
interface ActivityProps {
  // items: AuditLog[];
  items: any[];
}
export const Activity = ({
  items
}: ActivityProps) => {
  return <div className="flex w-full items-start gap-x-3" data-sentry-component="Activity" data-sentry-source-file="activity.tsx">
      <ActivityIcon className="mt-0.5 h-5 w-5 text-neutral-700 dark:text-neutral-200" data-sentry-element="ActivityIcon" data-sentry-source-file="activity.tsx" />
      <div className="w-full">
        <p className="mb-2 font-semibold text-neutral-700 dark:text-neutral-300">
          Activity
        </p>
        <ol className="mt-2 space-y-4">
          {items.map(item => <ActivityItem key={item.id} data={item} />)}
        </ol>
      </div>
    </div>;
};
Activity.Skeleton = function ActivitySkeleton() {
  return <div className="flex w-full items-start gap-x-3">
      <Skeleton className="h-6 w-6  bg-neutral-200 dark:bg-neutral-700" />
      <div className="w-full">
        <Skeleton className="mb-2 h-6  w-24 bg-neutral-200 dark:bg-neutral-700" />
        <Skeleton className="h-10 w-full  bg-neutral-200 dark:bg-neutral-700" />
      </div>
    </div>;
};