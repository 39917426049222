"use client";

import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { CalendarClock, BookmarkPlus, ListStart, Trash, User2 } from "lucide-react";
import { useAction } from "@/hooks/use-actions";
import { copyCard } from "@/actions/main-board-actions/copy-card";
import { deleteCard } from "@/actions/main-board-actions/delete-card";
import { useParams, useRouter } from "next/navigation";
import { toast } from "sonner";
import { useCardModal } from "@/hooks/use-card-model";
import { CardWithSubTodos } from "@/types/pm-ai-types/pmAi-types";
import { ElementRef, useRef, useState } from "react";
import { Layout } from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";
import { UpdateCardInfo } from "@/actions/main-board-actions/update-card-info";
import { z } from "zod";
import { CardWithAllRelationalInfo, TicketFormSchema } from "@/types/funnelbuilder-types/funnelbuilder-types";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import TagComponent from "@/components/funnelbuilder/tag";
import { Doc } from "@/convex/_generated/dataModel";
import { useForm } from "react-hook-form";
interface ExtraInfoProps {
  data: CardWithAllRelationalInfo;
}
export const ExtraInfo = ({
  data
}: ExtraInfoProps) => {
  const params = useParams();
  const queryClient = useQueryClient();
  const cardModal = useCardModal();
  const [title, setTitle] = useState(data.title);
  const router = useRouter();
  const [tags, setTags] = useState<Doc<"labels">[]>([]);
  const [contact, setContact] = useState("");
  const [search, setSearch] = useState("");
  const [contactList, setContactList] = useState<Doc<"leads">[]>([]);
  const saveTimerRef = useRef<ReturnType<typeof setTimeout>>();
  const [allTeamMembers, setAllTeamMembers] = useState<Doc<"users">[]>([]);
  // const [assignedTo, setAssignedTo] = useState(
  //   defaultData.ticket?.assignedUserId || ""
  // );

  const inputRef = useRef<ElementRef<"input">>(null);
  const {
    execute
  } = useAction(UpdateCardInfo, {
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: ["card", data._id]
      });
      queryClient.invalidateQueries({
        queryKey: ["card-logs", data._id]
      });
      toast.success(`Renamed to "${data.title}"`);
      setTitle(data.title);
    },
    onError: error => {
      toast.error(error);
    }
  });
  const {
    execute: executeCopyCard,
    isLoading: isLoadingCopy
  } = useAction(copyCard, {
    onSuccess: data => {
      toast.success(`Card "${data.title}" created`);
      cardModal.onClose();
    },
    onError: error => {
      toast.error(error);
    }
  });
  const {
    execute: executeDeleteCard,
    isLoading: isLoadingDelete
  } = useAction(deleteCard, {
    onSuccess: data => {
      toast.success(`Card "${data.title}" deleted`);
      cardModal.onClose();
    },
    onError: error => {
      toast.error(error);
    }
  });
  const onCopy = () => {
    const boardId = params.boardId as string;
    executeCopyCard({
      id: data._id,
      boardId
    });
  };
  const onDelete = () => {
    const boardId = params.boardId as string;
    executeDeleteCard({
      id: data._id,
      boardId
    });
  };
  const onBlur = () => {
    inputRef.current?.form?.requestSubmit();
  };
  const onSubmit = (formData: FormData) => {
    const title = formData.get("title") as string;
    const boardId = params.boardId as string;
    if (title === data.title) {
      return;
    }
    execute({
      title,
      boardId,
      id: data._id
    });
  };
  const form = useForm<z.infer<typeof TicketFormSchema>>({
    mode: "onChange",
    resolver: zodResolver(TicketFormSchema)
    // defaultValues: {
    //   name: defaultData.ticket?.title || "",
    //   description: defaultData.ticket?.description || "",
    //   value: String(defaultData.ticket?.value || 0),
    // },
  });
  const isLoading = form.formState.isLoading;
  const divStyle = "flex flex-row gap-2 font-semibold p-2 rounded-md  bg-neutral-100 dark:bg-neutral-700 text-sm  border-transparent relative w-full focus-visible:bg-white dark:focus-visible:bg-white/20 focus-visible:border-input mb-0.5 ";
  const textStyle = "text-sm  text-neutral-700 dark:text-neutral-200 truncate";
  return <div className="mb-4 mt-2 space-y-2 " data-sentry-component="ExtraInfo" data-sentry-source-file="card-extra-info.tsx">
      <p className="pb-2 text-sm font-semibold">Extra Info</p>

      <p className="text-xs font-semibold">Priority</p>
      <div className={divStyle}>
        <ListStart className=" scale-x-[-1] " size={18} data-sentry-element="ListStart" data-sentry-source-file="card-extra-info.tsx" />
        <p className={textStyle}>{data.priority ?? "N/A"}</p>
      </div>

      <p className="text-xs font-semibold">Due Date</p>
      <div className={divStyle}>
        <CalendarClock className=" scale-x-[-1] " size={18} data-sentry-element="CalendarClock" data-sentry-source-file="card-extra-info.tsx" />
        <p className={textStyle}>
          {format(data.dueDate || new Date(), "MMM dd yyyy") ?? "N/A"}
        </p>
      </div>

      <p className="text-xs font-semibold">Assigned To</p>
      <div className={divStyle}>
        <User2 className=" scale-x-[-1] " size={18} data-sentry-element="User2" data-sentry-source-file="card-extra-info.tsx" />
        {/* TODO PROD: Add assigned user */}
        {/* <p className={textStyle}>{data.assigned?.username ?? "N/A"}</p> */}
        <p className={textStyle}>{"JAMES BOND"}</p>
      </div>

      <p className="text-xs font-semibold">Labels</p>
      <div className={"relative mb-0.5 flex w-full flex-row gap-2  rounded-md border-transparent bg-neutral-50  p-2 text-sm font-semibold focus-visible:border-input focus-visible:bg-white dark:bg-neutral-700 dark:focus-visible:bg-white/20 "}>
        {data.labels?.length === 0 && <BookmarkPlus className=" scale-x-[-1] " size={18} />}

        <div className="flex flex-wrap items-center gap-2">
          {data.labels && data.labels.map(label => <TagComponent key={label._id} title={label.name} colorName={label.color ?? ""} />)}
        </div>
      </div>
    </div>;
};
ExtraInfo.Skeleton = function ExtraInfoSkeleton() {
  return <div className="mt-2 space-y-2">
      <Skeleton className="h-4 w-20 bg-neutral-200 dark:bg-neutral-700 " />
      <Skeleton className="h-8 w-full bg-neutral-200 dark:bg-neutral-700 " />
      <Skeleton className="h-8 w-full bg-neutral-200 dark:bg-neutral-700 " />
    </div>;
};