import { create } from "zustand";

interface AlertModalStore {
  isOpen: boolean;
  isLoading: boolean;
  item?: string;
  onDelete?: () => Promise<void>;
  onOpen: (item: string, onDelete: () => Promise<void>) => void;
  onClose: () => void;
  setIsLoading: (isLoading: boolean) => void;
}

export const useAlertModal = create<AlertModalStore>((set) => ({
  isOpen: false,
  isLoading: false,
  item: undefined,
  onDelete: undefined,
  onOpen: (item, onDelete) =>
    set({
      isOpen: true,
      item,
      onDelete,
      isLoading: false,
    }),
  onClose: () =>
    set({
      isOpen: false,
      item: undefined,
      onDelete: undefined,
      isLoading: false,
    }),
  setIsLoading: (isLoading) => set({ isLoading }),
}));
