import { Doc } from "@/convex/_generated/dataModel";
// import { db } from "@/lib/db";
// import { Prisma } from "@prisma/client";
// import Stripe from "stripe";
import { z } from "zod";

// export type PricesList = Stripe.ApiList<Stripe.Price>;

// export type TicketDetails = Prisma.PromiseReturnType<
//   typeof _getTicketsWithAllRelations
// >;

export const _getTicketsWithAllRelations = async (laneId: string) => {
  //   const response = await db.ticket.findMany({
  //     where: { laneId: laneId },
  //     include: {
  //       Assigned: true,
  //       Customer: true,
  //       Lane: true,
  //       Tags: true,
  //     },
  //   });
  //   return response;
};

export type CardWithAllRelationalInfo = Doc<"todos"> & {
  labels: Doc<"labels">[] | null;
  subTodos: Doc<"subTodos">[] | null;
  list: Doc<"lists"> | null;
  customers: Doc<"leads">[] | null;
  customer: Doc<"leads"> | null;
  assigned: Doc<"users"> | null;
};

const currencyNumberRegex = /^\d+(\.\d{1,2})?$/;

export const TicketFormSchema = z.object({
  name: z.string().min(1),
  description: z.string().optional(),
  value: z.string().refine((value) => currencyNumberRegex.test(value), {
    message: "Value must be a valid price",
  }),
  dueDate: z.optional(z.date({ required_error: "A due date is required" })),
  priority: z.enum(["Low", "Normal", "High", "Urgent"]),
});

export const ContactUserFormSchema = z.object({
  name: z.string().min(1, "Required"),
  email: z.string().email(),
});
