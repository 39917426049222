import { Plus } from "lucide-react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import AddTaskInline from "./add-task-inline";
import { Doc, Id } from "@/convex/_generated/dataModel";
import { useQuery } from "convex/react";
import { api } from "@/convex/_generated/api";
import { toast } from "sonner";
export const AddTaskWrapper = ({
  parentTask,
  projectId,
  hideProjects
}: {
  parentTask?: Doc<"todos">;
  projectId?: Id<"projectBoards">;
  hideProjects?: boolean;
}) => {
  const [showAddTask, setShowAddTask] = useState(false);
  const [labelOptions, setLabelOptions] = useState<Doc<"labels">[]>([]);
  const labelsResponse = useQuery(api.pmAi.labels.getLabels);
  useEffect(() => {
    if (labelsResponse?.error) {
      toast.error(labelsResponse.error.message);
      return;
    }
    if (labelsResponse?.data && labelsResponse.data.length > 0) {
      setLabelOptions(labelsResponse.data);
    }
  }, [labelsResponse]);
  return showAddTask ? <AddTaskInline setShowAddTask={setShowAddTask} parentTask={parentTask} projectId={projectId} hideProjects={hideProjects}
  // labelOptions={labelOptions}
  data-sentry-element="AddTaskInline" data-sentry-component="AddTaskWrapper" data-sentry-source-file="add-task-button.tsx" /> : <AddTaskButton onClick={() => setShowAddTask(true)} title={parentTask?._id ? "Add sub-task" : "Add task"} data-sentry-element="AddTaskButton" data-sentry-component="AddTaskWrapper" data-sentry-source-file="add-task-button.tsx" />;
};
export default function AddTaskButton({
  onClick,
  title
}: {
  onClick: Dispatch<SetStateAction<any>>;
  title: string;
}) {
  return <button className="mt-2 flex flex-1 pl-2" onClick={onClick} data-sentry-component="AddTaskButton" data-sentry-source-file="add-task-button.tsx">
      <div className="flex flex-col items-center justify-center gap-1 text-center">
        <div className="flex items-center justify-center gap-2">
          <Plus className="h-4 w-4 text-primary hover:rounded-xl hover:bg-primary hover:text-white" data-sentry-element="Plus" data-sentry-source-file="add-task-button.tsx" />
          <h3 className="text-sm font-semibold tracking-tight text-foreground/70">
            {title}
          </h3>
        </div>
      </div>
    </button>;
}