"use client";

import { useFormStatus } from "react-dom";
import { cn } from "@/lib/utils";
import { Button } from "@nextui-org/react";
import { ReactNode } from "react";

/*
variant	solid | bordered | light | flat | faded | shadow | ghost	The button appearance style.	solid
color	default | primary | secondary | success | warning | danger	The button color theme.	default
size	sm | md | lg	The button size.	md
radius	none | sm | md | lg | full	The button border radius.	-

isIconOnly	boolean	Whether the button should have the same width and height.	false
isDisabled	boolean	Whether the button is disabled.	false
isLoading	boolean	Whether the button is loading.	false
disableRipple	boolean

*/

interface FormSubmitProps {
  children: React.ReactNode;
  type?: string;
  disabled?: boolean;
  className?: string;
  variant?: "solid" | "bordered" | "light" | "flat" | "faded" | "shadow" | "ghost";
  color?: "default" | "primary" | "secondary" | "success" | "warning" | "danger";
  size?: "sm" | "md" | "lg";
  radius?: "none" | "sm" | "md" | "lg" | "full";
  isIconOnly?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  disableRipple?: boolean;
  onSubmit?: () => void;
  onClick?: () => void;
  onPress?: () => void;
  startContent?: ReactNode;
  endContent?: ReactNode;
}
export const FormSubmit = ({
  children,
  type,
  disabled,
  className,
  variant = "solid",
  color = "primary",
  size,
  radius,
  isIconOnly,
  isDisabled,
  isLoading,
  disableRipple,
  onSubmit,
  onClick,
  onPress,
  startContent,
  endContent
}: FormSubmitProps) => {
  const {
    pending
  } = useFormStatus();
  return <Button type="submit" disabled={pending || disabled} className={cn("rounded-md p-1 px-4", className)} variant={variant} color={color} size={size ? size : "sm"} radius={radius} isIconOnly={isIconOnly} isDisabled={pending || isDisabled} isLoading={isLoading} disableRipple={disableRipple} onSubmit={onSubmit} onClick={onClick} onPress={onPress} startContent={startContent} endContent={endContent} data-sentry-element="Button" data-sentry-component="FormSubmit" data-sentry-source-file="form-submit.tsx">
      {children}
    </Button>;
};

// "use client";

// import { useFormStatus } from "react-dom";

// import { cn } from "@/lib/utils";
// import { Button } from "@/components/ui/button";

// interface FormSubmitProps {
//   children: React.ReactNode;
//   disabled?: boolean;
//   className?: string;
//   variant?:
//     | "default"
//     | "destructive"
//     | "outline"
//     | "secondary"
//     | "ghost"
//     | "link"
//     | "primary";
// }

// export const FormSubmit = ({
//   children,
//   disabled,
//   className,
//   variant = "primary",
// }: FormSubmitProps) => {
//   const { pending } = useFormStatus();

//   return (
//     <Button
//       disabled={pending || disabled}
//       type="submit"
//       variant={variant}
//       size="sm"
//       className={cn(className)}
//     >
//       {children}
//     </Button>
//   );
// };